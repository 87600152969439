import {AccountSettingsPanel} from '../account-settings-panel';
import {Button} from '@common/ui/buttons/button';
import {DialogTrigger} from '@common/ui/overlays/dialog/dialog-trigger';
import {ConfirmationDialog} from '@common/ui/overlays/dialog/confirmation-dialog';
import {useDeleteAccount} from './delete-account';
import {Trans} from '@common/i18n/trans';
import {AccountSettingsId} from '@common/auth/ui/account-settings/account-settings-sidenav';

export function DangerZonePanel() {
  const deleteAccount = useDeleteAccount();

  return (
    <AccountSettingsPanel
      id={AccountSettingsId.DeleteAccount}
      title={<Trans message="Danger zone" />}
    >
  
  <div className="pl-10">
      <a href="https://www.interactiveshell.com/panel/support/new" target="_blank;">
        <Button variant="flat" color="danger">
          <Trans message="Support call to Delete account" />
        </Button>
      </a>
      </div>
  

    </AccountSettingsPanel>
  );
}
